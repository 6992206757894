import React from 'react';
import '../../App.css';

const Home: React.FC = () => {
    return <section className="s s-hm s-hm-claims cf sc-m wnd-background-image wnd-w-default wnd-s-normal wnd-h-auto wnd-nh-m wnd-p-cc hn-slogan wn-space-top">
        <div className="s-w cf">
            <div className="s-o cf">
                <div className="s-bg">
                    <div className="s-bg-l wnd-background-image  bgpos-center-center bgatt-scroll overlay-black">
                        <picture><img
                            src="https://nutrizioneandrealia.it/tinysrgb-dpr-2-h-650-w-940.jpeg?ph=ce5d960f58"
                            width="1880" height="1253" alt=""/>
                        </picture>

                    </div>
                </div>
                <div className="h-c s-c">
                    <div className="h-c-b">
                        <div className="h-c-b-i">
                            <h4 className="claim quote-claim cf"><span className="sit b claim-borders claim-nb cormorant claim-18">
	<span className="sit-w"><span className="sit-c"><em>Biologo Nutrizionista&nbsp;</em></span></span>
</span></h4>
                            <h1 className="claim cf"><span className="sit b claim-default claim-nb pt-serif claim-64">
	<span className="sit-w"><span className="sit-c"><strong>Andrea Lia, PhD</strong></span></span>
</span></h1>
                            <h3 className="claim cf"><span className="sit b claim-beside claim-nb lora claim-26">
	<span className="sit-w"><span className="sit-c"><strong>"Nutrirsi significa sopravvivere. Nutrirsi bene significa vivere meglio"</strong></span></span>
</span></h3>
                            <div className="b b-s b-s-t150 b-s-b150 b-btn b-btn-4">
                                <div className="b-btn-c i-a">
                                    <a className="b-btn-l" href="https://wa.me/message/HWFXYEAPHO54A1" rel="nofollow" target="_blank">
                                        <span className="b-btn-t">Fissa un appuntamento&nbsp;</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
};

export default Home;
